<template>
    <BT-Blade-Items
        hideBladeHeader
        hideHeader
        navigation="user-permissions"
        :onPullSuccessAsync="onPull">
        <template #body>
            <!-- <v-row> -->
                <!-- <v-col cols="12"> -->
                    <div class="d-flex align-center">
                        <v-subheader>Permissions</v-subheader>
                        <v-spacer />
                    </div>
                    
                    <v-tabs v-model="tab">
                        <v-tab>Templates</v-tab>
                        <v-tab>All Permissions</v-tab>
                    </v-tabs>
                <!-- </v-col>
                <v-col cols="12"> -->
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-row class="pt-3">
                                <v-col v-for="(t, ind) in currentTemplates" :key="ind" cols="12" sm="6">
                                    <v-card>
                                        <div class="d-flex align-center">
                                            <v-card-subtitle>{{ t.templateName }}</v-card-subtitle>
                                            <v-spacer />
                                            <v-slide-y-transition hide-on-leave group>
                                                <v-btn
                                                    class="ma-1"
                                                    key="1"
                                                    x-small
                                                    @click="toggleTemplate(t)">
                                                    <v-slide-x-transition hide-on-leave>
                                                        <v-icon v-if="t.isUsing" left class="success--text">mdi-check</v-icon>
                                                    </v-slide-x-transition>
                                                    {{ t.isUsing ? 'Using' : 'Use' }}</v-btn>
                                            </v-slide-y-transition>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col v-for="(p, ind) in currentPermissions" :key="ind" cols="12" sm="6">
                                    <v-card>
                                        <div class="d-flex align-center">
                                            <v-card-subtitle>{{ p.text }}</v-card-subtitle>
                                            <v-spacer />
                                            <v-slide-y-transition hide-on-leave group>
                                                <v-btn
                                                    v-if="!p.canEdit && p.isViewable"
                                                    class="ma-1"
                                                    key="1"
                                                    x-small
                                                    @click="togglePermissionView(p)">
                                                    <v-slide-x-transition hide-on-leave>
                                                        <v-icon v-if="p.canView || (p.isViewable && p.canEdit)" left class="success--text">mdi-check</v-icon>
                                                    </v-slide-x-transition>
                                                    View</v-btn>
                                                <v-btn
                                                    v-if="p.isEditable"
                                                    class="ma-1"
                                                    key="2"
                                                    x-small
                                                    @click="togglePermissionEdit(p)">
                                                    <v-slide-x-transition hide-on-leave>
                                                        <v-icon v-if="p.canEdit" left class="success--text">mdi-check</v-icon>
                                                    </v-slide-x-transition>
                                                    Edit</v-btn>
                                            </v-slide-y-transition>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                <!-- </v-col> -->
            <!-- </v-row> -->
        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';
//import { capitalizeWords } from '~helpers';

export default {
    name: 'Permissions',
    data: function() {
        return {
            allPermissions: [],
            currentPermissions: [],
            currentTemplates: [],
            tab: 0,
            templates: [
                {
                    templateName: 'Sales Operator', 
                    description: 'Process customers\' purchase orders, standing orders, and invoices.', 
                    permissions: [
                        'customers.view',
                        'customer-orders.edit',
                        'customer-invoices.edit',
                        'customer-invoice-external-parties.edit',
                        'locations.view',
                        'location-notes.edit',
                        'order-sheets.edit',
                        'standing-customer-orders.edit',
                    ]
                },
                {
                    templateName: 'Sales Manager',
                    description: 'Manager settings in relation to sales',
                    permissions: [
                        'account-links.edit',
                        'automatic-line-items.edit',
                        'customers.view',
                        'customer-orders.edit',
                        'customer-invoices.edit',
                        'customer-invoice-external-parties.edit',
                        'locations.view',
                        'location-notes.edit',
                        'ordering-functions.edit',
                        'order-invoicing-functions.edit',
                        'order-rules.edit',
                        'order-sheets.edit',
                        'order-slots.edit',
                        'order-slot-groups.edit',
                        'process-schedules.edit',
                        'product-offering-groups.edit',
                        'standing-customer-orders.edit',
                        'supplier-price-tiers.edit',
                    ]
                },
                {
                    templateName: 'User Manager',
                    description: 'Manage users and roles',
                    permissions: [
                        'roles.edit',
                        'users.edit',
                        'user-roles.edit',
                        'user-connections.edit'
                    ]
                },
                { 
                    templateName: 'Customer Manager', 
                    description: 'Manage agreements with customers, ordering schedules, etc.', 
                    permissions: [
                        'customers.edit', 
                        'customer-external-parties.edit',
                        'customer-settings.edit',
                        'customer-templates.edit', 
                        'customer-groups.edit', 
                        'customer-requests.edit', 
                        'customer-external-parties.edit', 
                        'stock-management-customers.edit', 
                        'create-customers.edit']
                },
                {
                    templateName: 'Stock Manager',
                    description: 'Manages anything to do with stock consignments and fulfilling those consignments',
                    permissions: [
                        'locations.view',
                        'products.view',
                        'product-tethers.edit',
                        'stock-adjustments.edit',
                        'stock-batches.edit',
                        'stock-increments.edit',
                        'stock-items.edit',
                        'stock-levels.edit',
                        'stock-management-settings.edit',
                        'stock-thresholds.edit',
                        'stock-tracking-settings.edit',
                        'stock-transfers.edit',
                        'stock-consignments.edit',
                        'supply-location-suggestions.view',
                        'supply-pointers.edit',
                    ]
                },
                { 
                    templateName: 'Courier Operator',
                    description: 'Request dispatch of consignments',
                    permissions: [
                        'couriers.view',
                        'courier-orders.edit',
                    ]
                },
                { 
                    templateName: 'Driver', 
                    description: 'Perform deliveries and complete journeys.', 
                    permissions: [
                        'courier-packing.edit',
                        'deliveries.edit',
                        'journeys.edit',
                        'locations.view',
                        'location-notes.edit',
                        'measurements.view',
                        'products.view', 
                        'releases.edit',
                        'stock-increments.view',
                        'stock-consignments.edit',
                        'customer-orders.view']
                },
                { 
                    templateName: 'Factory Operator', 
                    description: 'Create, release, and remove batches.', 
                    permissions: [
                        'locations.view', 
                        'products.view',
                        'stock-batches.edit']
                },
                { 
                    templateName: 'Picking Operator', 
                    description: 'Assign and pick stock items to fill orders', 
                    permissions: [
                        'stock-consignments.edit', 
                        'stock-items.edit']
                },
                {
                    templateName: 'Packing Operator',
                    description: 'Pack consignments for dispatch',
                    permissions: [
                        'courier-packing.edit', 
                        'measurements.view']
                },
                { 
                    templateName: 'Routing Operator', 
                    description: 'Plan routes, pick-ups, and drop-offs for journeys.', 
                    permissions: [
                        'route-suggestions.view', 
                        'movements.edit', 
                        'journeys.edit', 
                        'client-orders.edit', 
                        'journey-suggestions.view']
                },
                { 
                    templateName: 'Routing Manager', 
                    description: 'Manage route pointers, journey pointers, and journey templates.', 
                    permissions: [
                        'route-suggestions.view', 
                        'movements.edit', 
                        'journeys.edit', 
                        'client-orders.edit', 
                        'route-pointers.edit', 
                        'journey-pointers.edit', 
                        'journey-suggestions.view', 
                        'journey-templates.edit']
                },
            ],
        }
    },
    props: {
        value: null
    },
    watch: {
        value(v) {
            this.refresh(v);
        }
    },
    // mounted() {
    //     this.refresh(this.value);
    // },
    computed: {
        groups() {
            return this.allPermissions
                .reduce((prev, curr) => {
                    if (!prev.some(z => z == curr.groupName)) {
                        prev.push(curr.groupName);
                    }

                    return prev;
                }, [])
                .sort(firstBy(x => x, { ignoreCase: true }));
        }
    },
    methods: {
        onPull(list) {
            this.allPermissions = list;
            this.refresh(this.value);
        },
        refresh(str) {
            // if (str == null) {
            //     this.currentPermissions = [];
            //     this.currentTemplates = [];
            //     return;
            // }

            //calc permissions
            var allPermissions = this.allPermissions
                .reduce((prev, curr) => {
                    var split = curr.permissionValue.split('.');
                    var p = Object.assign({}, {
                        text: this.capitalizeWords(split[0].replaceAll('-', ' ')),
                        value: split[0],
                        isEditable: false,
                        isViewable: false,
                        isAble: false,
                        canEdit: false,
                        canView: false,
                        can: false,
                        group: curr.groupName
                    });

                    var existing = prev.find(y => y.value == p.value);
                    if (existing == null) {
                        existing = p;
                        prev.push(existing);
                    }

                    if (split.length > 1) {
                        //can view or can edit?
                        if (split[1] == 'edit') {
                            existing.isEditable = true;
                        }
                        else if (split[1] == 'view') {
                            existing.isViewable = true;
                        }
                    }
                    else {
                        existing.isAble = true;
                    }
                    
                    return prev;
                }, [])
                .sort(firstBy(x => x.text, { ignoreCase: true }));
            
            var rolePermissions = str != null ? str.split(',') : []; //roles.flatMap(x => x.permissions != null ? x.permissions.split(',') : []);

            rolePermissions.forEach(rPerm => {
                var split = rPerm.split('.');

                var existingP = allPermissions.find(x => x.value == split[0]);
                if (existingP != null) {
                    if (split.length > 1) {
                        if (split[1] == 'edit') {
                            existingP.canEdit = true;
                        }
                        else if (split[1] == 'view') {
                            existingP.canView = true;
                        }
                    }
                    else {
                        existingP.can = true;
                    }
                }
            });

            this.currentPermissions = allPermissions;

            //calc templates
            this.currentTemplates = this.templates.map(template => {
                var res = true;
                var ePerms = this.value != null ? this.value.split(',').map(x => { return {
                    left: x.split('.')[0],
                    right: x.split('.')[1]
                }}) : [];

                template.permissions.forEach(x => {
                    var val = x.split('.')[0];

                    var existingPerm = ePerms.find(ePerm => ePerm.left == val);
                    if (existingPerm == null) {
                        res = false;
                    }
                    else {
                        if (!(existingPerm.right == 'edit' || x.split('.')[1] == 'view')) {
                            res = false;
                        }
                    }
                })

                return Object.assign({
                    templateName: template.templateName,
                    description: template.description,
                    permissions: template.permissions,
                    isUsing: res
                });
            })
        },
        toggleTemplate(template) {
            // var rList = this.value != null ? this.value.split(',') : [];
            template.isUsing = !template.isUsing;
            var tUsing = this.currentTemplates.filter(x => x.isUsing);

            template.permissions.forEach(p => {
                if (p == null) {
                    return;
                }
                //if no other template use this permission
                var pLeft = p.split('.')[0];
                var pRight = p.split('.')[1] ?? null;

                var existP = this.currentPermissions.find(x => x.value == pLeft);

                if (existP != null) {
                    if (template.isUsing) {
                        //then add
                        if (pRight == null) {
                            existP.can = true;
                        }
                        if (pRight == 'edit') {
                            existP.canEdit = true;
                        }
                        if (pRight == 'view') {
                            existP.canView = true;
                        }
                    }
                    else {
                        //try remove
                        if (!tUsing.some(x => x.permissions.some(perm => perm == p))) {
                            //no other templates using this permission so remove it
                            if (pRight == null) {
                                existP.can = false;
                            }
                            if (pRight == 'edit') {
                                existP.canEdit = false;
                            }
                            if (pRight == 'view') {
                                existP.canView = false;
                            }
                        }
                    }
                }
            })

            this.apply();
        },
        togglePermissionView(pItem) {
            pItem.canView = !pItem.canView;
            this.apply();

            // var v = pItem.value + '.view';
            // if (pItem.canEdit) {
            //     return;
            // }

            // var rList = this.value != null ? this.value.split(',') : [];
            // if (pItem.canView) {
            //     //remove
            //     rList = rList.filter(y => y != null && y.length > 0 && y != v);
            //     this.$emit('input', rList.length == 0 ? null : rList.toString());
            // }
            // else {
            //     //add
            //     rList.push(v);
            //     this.$emit('input', rList.toString());
            // }

            // pItem.canView = !pItem.canView;
        },
        togglePermissionEdit(pItem) {
            pItem.canEdit = !pItem.canEdit;
            this.apply();
            // console.log('toggling edit');
            // var v = pItem.value + '.edit';

            // var rList = this.value != null ? this.value.split(',') : [];
            // if (pItem.canEdit) {
            //     //remove
            //     rList = rList.filter(y => y != null && y.length > 0 && y != v);
            //     this.$emit('input', rList.length == 0 ? null : rList.toString());
            // }
            // else {
            //     //add
            //     //remove view
            //     rList = rList.filter(y => y != null && y.length > 0 && y != (pItem.value + '.view'));
            //     rList.push(v);

            //     this.$emit('input', rList.toString());
            // }

            // pItem.canEdit = !pItem.canEdit;
        },
        apply() {
            var rList = []

            this.currentPermissions.forEach(p => {
                if (p.canView) {
                    rList.push(`${p.value}.view`);
                }
                if (p.canEdit) {
                    rList.push(`${p.value}.edit`);
                }
                if (p.can) {
                    rList.push(`${p.value}`);
                }
            })

            this.$emit('input', rList.toString());
        }
    }
}
</script>